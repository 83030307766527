.header {
    display: flex;
    flex-direction: column;
    font-family:'Times New Roman, Times, serif';
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    background-color: #D8DCDF;
}

.header h1 {
    margin-top: .5rem;
    margin-bottom: 0.25rem;
    font-size: 23px;
}

.header p {
    text-decoration: none;
    font-size: 30px; 
    font-weight: bold;
    width: 25rem;
    display: inline-block;
    text-align: center;
    margin: 20px 2px 1px 2px;
}
