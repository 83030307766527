.header {
    display: flex;
    justify-content: center;
    font-family:'Times New Roman, Times, serif';
    margin: 1rem auto;
    max-width: 40rem;
}

.links {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-bottom: 2rem;
    border-bottom: 2px solid #ccc;
    width: 40rem;
}

.links a {
    text-decoration: none;
    font-size: 1.25rem;
    width: 9rem;
    display: inline-block;
    text-align: center;
    color: #6d6674;
}

.active {
    font-weight: bold;
    color: #35224a;
}