.homeContent {
    display: flex;
    flex-direction: row;
}

.textContent {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.textParagraph {
    font-family:'Times New Roman, Times, serif';
    font-size: 24px;
    margin-top: 1em;
    width: 530px;
}

.marginTop2em {
    margin-top: 2em;
}

.aiRobotDiv {
    height: 400px;
    width: 350px;
    padding-left: 50px;
    margin-left: 100px;
}

.aiRobotImg {
    height: 300px;
    width: 300px;
}