.contactContent {
    display: flex;
    flex-direction: column;
    margin-left: 200px;
}

.contactTextContent {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.contactContentRow {
    display: flex;
    flex-direction: row;
}

.textContentParagraph {
    font-family:'Times New Roman, Times, serif';
    font-size: 20px;
    padding-top: 0.5em;
}

.textContentParagraphPhone {
    font-family:'Times New Roman, Times, serif';
    font-size: 20px;
    padding-top: 0.5em;
    padding-left: 0.75em;
}

.boldFont {
    font-weight: bold;
}

.marginTop1em {
    margin-top: 1em;
}
.marginTop2em {
    margin-top: 2em;
}

.paddingLeftZeroPoint5em {
    padding-left: 0.5em;
}